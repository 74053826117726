// pages with special param constraints
const MARKETINGPRO_INAPP = '/marketingpro-inapp';

const checkQueryParamContraints = (pathname, param, value) => {
  switch (pathname) {
    case MARKETINGPRO_INAPP:
      if (param === 'unsoldestimates') return value > 2 ? value : '';
      if (param === 'idleaccounts') return value > 2 ? value : '';
      break;
    default:
      return value;
  }
  return value;
};

export default checkQueryParamContraints;
