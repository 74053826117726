import { useStaticQuery, graphql } from 'gatsby';

const usePagePath = ({ location }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  return site.siteMetadata.siteUrl + location.pathname;
};

export default usePagePath;
