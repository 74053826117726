import { graphql } from 'gatsby';
import styled from 'styled-components';
import React from 'react';
import useBuildPageComponents from 'utils/useBuildPageComponents';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ContentfulEditWidget from 'components/ContentfulEditWidget';
import withReplacement from './withReplacement';
import PageWrapper from './PageWrapper';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
      xxxl: 2000,
    },
  },
});

const Page = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledPage $customCss={props?.data?.contentfulPage?.styles?.customStyling?.customStyling}>
        <ContentfulEditWidget id={props?.data?.contentfulPage?.contentful_id} isPage />
        <PageWrapper {...props}>{useBuildPageComponents(props)}</PageWrapper>
      </StyledPage>
    </ThemeProvider>
  );
};

export default withReplacement(Page);

export const PageQuery = graphql`
  query PageQuery($pageUrl: String!) {
    contentfulPage(seo: { pageUrl: { eq: $pageUrl } }) {
      ...Page
    }
  }
`;

const StyledPage = styled.div`
  ${({ $customCss }) => $customCss};
`;
