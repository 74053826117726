import React from 'react';
import queryString from 'query-string';
import { Location } from '@reach/router';
import checkQueryParamContraints from 'utils/queryParamConstraints';

const withReplacement = (ComponentToWrap) => (props) => {
  return (
    <Location>
      {({ location }) => {
        // retrieve query params
        const queryParams = location.search ? queryString.parse(location.search) : {};

        // regex: any text between '[]' lowercase a-z
        const regex = /\[[a-z]*\S\]/g;

        // find all instances in page content
        const matches = JSON.stringify(props?.data).match(regex) || [];

        // if query params exist to replace a matching instance, perform replacement.
        let data =
          Object.keys(queryParams).length > 0
            ? JSON.parse(
                Object.keys(queryParams).reduce((prev, cur) => {
                  return prev.replace(
                    `[${cur}]`,
                    checkQueryParamContraints(location.pathname, cur, queryParams[cur]),
                  );
                }, JSON.stringify(props?.data)),
              )
            : props?.data;

        // if any instances were not included in query params, replace with empty string.
        data = JSON.parse(
          matches.reduce((prev, cur) => {
            return prev.replace(cur, '');
          }, JSON.stringify(data)),
        );

        return <ComponentToWrap {...props} data={data} queryParams={queryParams} />;
      }}
    </Location>
  );
};

export default withReplacement;
