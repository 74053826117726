import legalIcon from 'images/legal-gray.svg';
import legalIconLight from 'components/Page/PrivacyRequest/images/legal-lightgray.svg';
import { useCheckHeroDark } from './colorUtility';

const removeSlash = (slug) => (slug.startsWith('/') ? slug.substr(1, slug.length) : slug);
const MP = 'marketingpro';

const formatTitle = (route) => {
  if (route === MP) return 'Marketing Pro';
  if (route.includes('hvac')) return route.replace('hvac', 'HVAC');
  if (route.includes('quickbooks')) return route.replace('quickbooks', 'QuickBooks');
  return route;
};

const useGenerateBreadcrumbs = (seo, hero, theme) => {
  const { pageUrl, showBreadcrumbs } = seo || {};
  const checkHeroDark = useCheckHeroDark(hero);
  const isHeroDark = theme === 'light' || checkHeroDark;

  if (showBreadcrumbs) {
    const crumbData = [
      {
        title: 'Home',
        icon: null,
        url: '/',
      },
    ];
    const pages = removeSlash(pageUrl).split('/');

    pages.forEach((route, index) => {
      const prevUrl = crumbData[index].url;
      crumbData.push({
        title: formatTitle(route)
          .split('-')
          .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
          .join(' '),
        icon: route === 'legal' ? (isHeroDark ? legalIconLight : legalIcon) : null,
        url: index === pages.length - 1 ? null : `${index !== 0 ? prevUrl : ''}/${route}`,
      });
    });
    return {
      data: crumbData,
      absolute: hero && true,
    };
  }
  return null;
};
export default useGenerateBreadcrumbs;
