module.exports = {
  siteTitle: 'ServiceTitan',
  siteTitleAlt: 'ServiceTitan', // This allows an alternative site title for SEO schema.
  publisher: 'ServiceTitan', // Organization name used for SEO schema
  siteDescription:
    'The leading service businesses run on ServiceTitan. Learn why we are the #1 home service software & Mobile App for home service companies.',
  siteUrl: 'https://www.servicetitan.com', // Site domain. Do not include a trailing slash!
  authorUrl: 'https://www.servicetitan.com',
  shortTitle: 'ServiceTitan',
};
