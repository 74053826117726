/* eslint-disable react/no-unknown-property */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import usePagePath from 'hooks/usePagePath';
import favicon from 'images/favicon.ico';
import config from 'utils/siteConfig';
import { updatePageLoaded, updateAudience } from 'services/dataLayerService';
import { generateFAQSchema, getRobot, generateBlogSchema } from 'utils/seo';

const SEO = (props) => {
  const {
    pageSEO,
    postSEO,
    robotOptions,
    pageNode,
    pagePath,
    canonicalUrl,
    productSchema,
    ogTagImage,
    slug,
    pageCategory,
    faq,
    audience,
    breadcrumb,
    headHtml,
    taxonomy,
    blogSchema,
    ...otherProps
  } = props;
  let title;
  let description;
  let websitePageSchema;
  let faqSchema;
  let breadcrumbSchema;
  title = config.siteTitle;
  description = config.siteDescription;

  useEffect(() => {
    if (pageCategory || taxonomy) {
      updatePageLoaded(pageCategory, taxonomy);
    }
    const audienceData = audience === 'Both' ? 'Customer, Prospect' : audience;
    updateAudience(audienceData);
  }, [taxonomy, pageCategory, audience]);

  // If Normal Page
  if (pageNode) {
    title = pageNode.title;
    description = pageNode.metaDescription.internal.content;
  }

  // Default Website Schema
  const websiteSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: config.siteUrl,
    name: title,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
  };

  // Page SEO Schema
  if (pageSEO) {
    websitePageSchema = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      url: pagePath,
      name: title,
    };
  }

  // FAQ SEO Schema
  if (faq) {
    faqSchema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faq,
    };
  }

  const robot = getRobot(robotOptions, slug);

  return (
    <Helmet>
      <script src="//app-sj26.marketo.com/js/forms2/js/forms2.min.js" async="true" />
      <link rel="icon" href={favicon} />
      {/* General tags */}
      {canonicalUrl && robotOptions?.indexable && <link rel="canonical" href={canonicalUrl} />}
      <title>{title}</title>
      {/* <meta name="image" content={image} /> */}
      <meta name="description" content={description} />
      {robot}
      {/* Schema.org tags */}
      <script type="application/ld+raw">{JSON.stringify(websiteSchema)}</script>
      {websitePageSchema && (
        <script type="application/ld+raw">{JSON.stringify(websitePageSchema)}</script>
      )}
      {blogSchema && <script type="application/ld+raw">{JSON.stringify(blogSchema)}</script>}
      {faqSchema && <script type="application/ld+raw">{JSON.stringify(faqSchema)}</script>}
      {productSchema && <script type="application/ld+json">{JSON.stringify(productSchema)}</script>}
      <script type="text/javascript" src="//cdn-0.d41.co/tags/ff-3.min.js" charset="utf-8" />
      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      {postSEO ? <meta property="og:type" content="article" /> : null}
      <meta
        property="og:image"
        content={
          ogTagImage
            ? `https:${ogTagImage}`
            : 'https://images.ctfassets.net/3q1a0w8cwuwf/7ISRCzbAIAcs4MGNvrYSWN/67264761ca3557c758165afc41dc04a9/ServiceTitan_Logo_Black_2.png'
        }
      />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={config.siteTitle} />
      <meta property="og:url" content={pagePath} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
      />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {headHtml && parse(headHtml)}
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;

export const createSEO = (contentfulSeo, props, contentfulFaq = {}) => {
  const metaDescription = contentfulSeo?.metaDescription?.metaDescription;
  const pageUrl = contentfulSeo?.pageUrl || contentfulSeo?.slug;
  const pageTitle = contentfulSeo?.pageTitle || contentfulSeo?.titleTag || contentfulSeo?.title;
  const indexibility = contentfulSeo?.indexibility?.indexibility || contentfulSeo?.indexable;
  const featuredImage =
    contentfulSeo?.featuredImage?.image?.file?.url ||
    contentfulSeo?.socialSharingImage?.image?.file?.url;
  const canonicalUrl = contentfulSeo?.canonicalUrl;
  const pageCategory = contentfulSeo?.pageCategory || contentfulSeo?.pageCategories;
  const pagePath = usePagePath(props);
  const faq = generateFAQSchema(contentfulFaq?.components);
  const audience = contentfulSeo?.audience;
  const blogSchema =
    contentfulSeo?.pageCategory === 'Blog' && generateBlogSchema(contentfulSeo, props);
  const headHtml = contentfulSeo?.headHtml?.headHtml;
  const taxonomy = contentfulSeo?.taxonomy;

  const seo = {
    pageSEO: true,
    robotOptions: {
      indexable:
        indexibility === 'Non-Indexable' ? false : indexibility === 'Indexable' ? true : null,
      ...(contentfulSeo?.robotOptions || {}),
    },
    pageNode: {
      title: pageTitle,
      metaDescription: {
        internal: {
          content: metaDescription,
        },
      },
    },
    ogTagImage: featuredImage,
    pagePath: pagePath || pageUrl,
    canonicalUrl: canonicalUrl || pagePath || `https://www.servicetitan.com/${pageUrl}`,
    productSchema:
      ['Industry', 'Feature', 'Demo', 'Tools', 'Comparison'].includes(pageCategory) ||
      pagePath.includes('pricing')
        ? {
            '@context': 'http://schema.org',
            '@type': 'Product',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.5',
              reviewCount: '679',
            },
            name: 'ServiceTitan',
          }
        : null,
    slug: pageUrl,
    pageCategory,
    faq,
    audience,
    headHtml,
    taxonomy,
    blogSchema,
  };

  return seo;
};
