import React from 'react';
import { useCheckHeroDark } from 'utils/colorUtility';
import ContentfulEditWidget from 'components/ContentfulEditWidget';
import ComponentMap from '../components/Contentful/ComponentMap';

const useBuildPageComponents = (props) => {
  const components = props?.components || props?.data?.contentfulPage?.components;
  const { seo } = props?.data?.contentfulPage;
  const { pageUrl } = seo || {};

  const isHero = (ele) =>
    ele.__typename === 'ContentfulDynamicHero' ||
    (ele.__typename === 'ContentfulSection' && ele.isHero) ||
    (ele.__typename === 'ContentfulLayout' && ele.template?.isHero);

  const hero = components?.find((ele) => isHero(ele));

  const checkHeroDark = useCheckHeroDark(hero);
  const isHeroDark = props.isHeroDark || checkHeroDark;

  return (
    <div>
      {components?.map((ele, index) => {
        const type = ele.__typename;
        const anchorId = ele.anchorId || ele.contentful_id;
        const optimizelySelector = ele?.optimizelySelector;
        const pageType = ['terms-of-use', 'privacy-policy'].includes(pageUrl) ? 'terms' : 'normal';
        const slug = pageUrl;
        const componentProps = {
          anchorId,
          ...ele,
          ...(type === 'Contentful2ColumnLayout' ? { pageType, slug } : {}),
          ...(type === 'ContentfulDynamicHero' ? { seo } : {}),
          ...(type === 'ContentfulHeader' ? { isHeroDark } : {}),
        };

        const Component = ComponentMap[type];
        const classNames = ['relative', isHero(ele) ? 'hero' : '', optimizelySelector]
          .filter(Boolean)
          .join(' ');

        return Component ? (
          <div
            id={`section${ele?.contentful_id ? `-${ele?.contentful_id}` : ''}`}
            className={classNames}
            key={`page-section-${index}`}
          >
            {type !== 'ContentfulHeader' && (
              <ContentfulEditWidget
                id={ele?.contentful_id}
                isHero={
                  hero?.contentful_id === ele?.contentful_id ||
                  hero?.contentful_id === ele?.section?.contentful_id
                }
              />
            )}
            <Component {...componentProps} key={index} />
          </div>
        ) : null;
      })}
    </div>
  );
};

export default useBuildPageComponents;
